import React, { useState, useContext, useEffect } from 'react';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	Cell,
} from '@table-library/react-table-library/table';
import {
	useSort,
	HeaderCellSort,
} from '@table-library/react-table-library/sort';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@table-library/react-table-library/theme';
import CustomOptionsMenu from '../../../components/customOptionsMenu';
import NobleIcon from '../../../components/nobleIcon';
import cn from 'classnames';
import { deleteMemberById } from '../../../services/api/members/deleteMember';
import { toast } from 'react-toastify';

import './membersList.scss';
import UserMemberModal from '../../../components/modals/userMemberModal';
import DeleteModal from '../../../components/modals/deleteModal';
import { AppContext, AuthContext } from '../../../context';
import { getTotalMemebers } from '../../../services/api/members/getTotalMembers';
import { deleteMemberMessage } from '../../../services/constants';
import { sendPaymentReminder } from '../../../services/api/members/sendReminder';
import CancelMembershipModal from 'components/modals/cancelMembershipModal';
import { cancelMembershipById } from '../../../services/api/members/cancelMembershipById';
import { userHasPermission } from 'helpers/hasPermission';
import { PERMISSIONS } from 'helpers/constants';
import { getTheme } from './tableStyles';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function MembersList({ data, limit, pagination, ...rest }) {
	const pageRange = 2;
	const [clickedItem, setClickedItem] = useState(null);
	const [actionItem, setActionItem] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenDeleteMemberModal, setisOpenDeleteMemberModal] = useState(false);
	const [isOpenCancelMembershipModal, setisOpenCancelMembershipModal] =
		useState(false);
	const { setLoading, setTotalMembers, setRefetchMembers, membersSelected } =
		useContext(AppContext);
	const { user } = useContext(AuthContext);
	const [dataFiltered, setDataFiltered] = useState(data);

	const openMemberHandler = (event, member, action) => {
		event.stopPropagation();
		if (event.target.classList.contains('ThreeDotsOptionsMenu__item-edit')) {
			return;
		}
		setClickedItem(member);
		setActionItem(action);
		setIsOpen(true);
	};

	const openEditMemberHanlder = (member) => {
		setClickedItem(member);
		if (member.is_spouse) setActionItem('edit-spouse');
		if (!member.is_spouse) setActionItem('edit-member');
		setIsOpen(true);
	};

	const closeHandler = () => {
		setIsOpen(!isOpen);
		setClickedItem(null);
		setActionItem(null);
	};

	const openDeleteMemberHanlder = (item) => {
		setClickedItem(item);
		setisOpenDeleteMemberModal(true);
	};
	const closeHandlerDeleteMemberModal = () => {
		setisOpenDeleteMemberModal(false);
		setClickedItem(null);
	};

	const deleteMember = async (memberId) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_DELETE_MEMBER))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await deleteMemberById(memberId);
		if (!response.error) {
			const totalMember = await getTotalMemebers();
			if (!totalMember.error) {
				setRefetchMembers(true);
				setTotalMembers(totalMember);
			}
			setisOpenDeleteMemberModal(false);
			toast.success(`Member deleted`, toastOptions);
		} else {
			setisOpenDeleteMemberModal(false);
			toast.error(`Error deleting Member`, toastOptions);
		}
		setLoading(false);
	};

	const sortBySubscriptionType = (arrMembers) => {	
		let arrNullMembers = [];
		let arrAnnualMembers = [];
		let arrMonthlyMembers = [];		
		for (var i = 0; i < arrMembers.length; i++) {
			if (arrMembers[i].subscription.type === null)
				arrNullMembers.push(arrMembers[i]);
			if (arrMembers[i].subscription.type === "Annual")
				arrAnnualMembers.push(arrMembers[i]);
			if (arrMembers[i].subscription.type === "Monthly")
				arrMonthlyMembers.push(arrMembers[i]);
		}
		let arrResultMembers = arrAnnualMembers.concat(arrMonthlyMembers).concat(arrNullMembers);
		return arrResultMembers;
	};

	const sortBySubscriptionStatus = (arrMembers) => {
		let arrNullMembers = [];
		let arrNotNullMembers = [];
		for (var i = 0; i < arrMembers.length; i++) {
			if (arrMembers[i].subscription.status === null)
				arrNullMembers.push(arrMembers[i]);
			if (arrMembers[i].subscription.status !== null)
				arrNotNullMembers.push(arrMembers[i]);
		}
		let arrOrderedMembers = arrNotNullMembers.sort((a, b) =>
			a.subscription.status.localeCompare(b.subscription.status),
		);
		let arrResultMembers = arrOrderedMembers.concat(arrNullMembers);
		return arrResultMembers;
	};

	const sort = useSort(
		dataFiltered,
		{
			onChange: onSortChange,
		},
		{
			sortIcon: {
				margin: '0px',
				iconDefault: <NobleIcon name="sortByButton" />,
				iconUp: <NobleIcon name="switchUp" />,
				iconDown: <NobleIcon name="switchDown" />,
			},
			sortFns: {
				FULLNAME: (array) => {
					return array.sort((a, b) => a.full_name.localeCompare(b.full_name))
				},
				ID: (array) =>
					array.sort((a, b) => a?.member_number - b?.member_number),
				EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
				BIRTHDAY: (array) =>
					array.sort((a, b) => new Date(a.birth_date) - new Date(b.birth_date)),
				MEMBERSINCE: (array) =>
					array.sort(
						(a, b) => new Date(a.member_since) - new Date(b.member_since),
					),
				TIER: (array) =>
					array.sort((a, b) => a.tier.type.localeCompare(b.tier.type)),
				PAYMENT: (array) => array.sort((a, b) => a.tier.amount - b.tier.amount),
				STATUS: (array) => sortBySubscriptionStatus(array),
				CANCELLATIONDATE: (array) => array.sort((a, b) => new Date(a.subscription.canceled_at) - new Date(b.subscription.canceled_at)),
				SUBSCRIPTIONTYPE: (array) => sortBySubscriptionType(array),
			},
		},
	);

	useEffect(() => {
		if (data) setDataFiltered(data);
	}, [data]);

	const totalPages = pagination.state.getTotalPages(dataFiltered.nodes);
	const getPageNumbers = () => {
		const currentPage = pagination.state.page + 1;
		const totalPagesToShow = Math.min(pageRange * 2 + 1, totalPages);
		let startPage = Math.max(1, currentPage - pageRange);
		let endPage = Math.min(totalPages, currentPage + pageRange);

		if (endPage - startPage + 1 < totalPagesToShow) {
			if (currentPage < pageRange + 1) {
				endPage = Math.min(startPage + totalPagesToShow - 1, totalPages);
			} else if (currentPage + pageRange > totalPages) {
				startPage = Math.max(endPage - totalPagesToShow + 1, 1);
			}
		}

		return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
	};
	const renderTotalPages = totalPages === 0 ? 1 : totalPages;
	const renderCurrentPage = pagination.state.page > renderTotalPages ? renderTotalPages : pagination.state.page + 1;
	const hasNextPage = renderTotalPages > 1;

	const sendReminder = async (item) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_SEND_MEMBER_REMINDERS))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await sendPaymentReminder(item?.id, item?.is_spouse);
		if (!response.error) {
			setRefetchMembers(true);
			toast.success(`The reminder was sent`, toastOptions);
		} else {
			toast.error(
				`Error: ${response?.data?.response_description}`,
				toastOptions,
			);
		}
		setIsOpen(false);
		setLoading(false);
	};

	function onSortChange(action, state) {}

	const activeTheme = useTheme(getTheme('active'));
	const inactiveTheme = useTheme(getTheme());

	const cancelMembership = async (memberId) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_CANCEL_MEMBER_MEMBERSHIP))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await cancelMembershipById(memberId);
		if (response && !response?.error) {
			setRefetchMembers(true);
			toast.error(`Membership canceled`, toastOptions);
		} else {
			toast.error(`Error canceling memberhsip`, toastOptions);
		}
		closeCancelMembershipModal();
		setLoading(false);
	};
	const openCancelMembership = (item) => {
		setClickedItem(item);
		setisOpenCancelMembershipModal(true);
	};
	const closeCancelMembershipModal = () => {
		setisOpenCancelMembershipModal(false);
		setClickedItem(null);
	};

	let paddingBottom = '0px';
	if (data?.nodes ) {
		if (data.nodes.length === 0)paddingBottom = '50px';
		if (data.nodes.length === 1)paddingBottom = '100px';
		if (data.nodes.length === 2 )paddingBottom = '50px';
		if (data.nodes.length === 3)paddingBottom = '50px';
	}

	return (
		<div className="MembersList" {...rest}>
			<Table
				data={dataFiltered}
				theme={ membersSelected.selected === 'active' ? activeTheme : inactiveTheme}
				sort={sort}
				pagination={pagination}
				style={{ height: "auto", marginTop: "-10px", paddingBottom: paddingBottom }}
				layout={{
					fixedHeader: true,
					custom: true,
				}}>
				{(tableList) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCellSort sortKey="FULLNAME" resize pinLeft>
									Name
								</HeaderCellSort>
								<HeaderCellSort sortKey="ID" resize>
									ID
								</HeaderCellSort>
								<HeaderCellSort sortKey="EMAIL" resize>
									Email
								</HeaderCellSort>
								<HeaderCellSort sortKey="BIRTHDAY" resize>
									Birthday
								</HeaderCellSort>
								<HeaderCellSort sortKey="MEMBERSINCE" resize>
									Date Joined
								</HeaderCellSort>
								{membersSelected.selected === 'inactive' && (
									<HeaderCellSort sortKey="CANCELLATIONDATE" resize>
										Cancellation Date
									</HeaderCellSort>
								)}
								<HeaderCellSort sortKey="SUBSCRIPTIONTYPE" resize>
									Subscription Type
								</HeaderCellSort>
								<HeaderCellSort sortKey="PAYMENT">
									Cost
								</HeaderCellSort>
								<HeaderCellSort sortKey="TIER" resize>
									Tiers
								</HeaderCellSort>
								<HeaderCellSort sortKey="STATUS">Subscription Status</HeaderCellSort>
							</HeaderRow>
						</Header>
						<Body>
							{tableList.map((item) => (
								<Row
									key={'adminKey_' + item?.member_number + item.id}
									item={item}
									className={cn([''], {
										active: item?.id === clickedItem,
									})}>
									<Cell
										pinLeft
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.full_name} {item.is_spouse ? 'ⓢ' : ''}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item?.member_number || '-'}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.email}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.birth_date || '-'}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.member_since || '-'}
									</Cell>
									{membersSelected.selected === 'inactive' && (
										<Cell
											onClick={(event) => {
												openMemberHandler(event, item, 'info');
											}}>
											{item.subscription.canceled_at || '-'}
										</Cell>
									)}
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.subscription.type || '-'}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.tier.amount ? `$ ${item.tier.amount}` : '-'}
									</Cell>
									<Cell
										onClick={(event) => {
											openMemberHandler(event, item, 'info');
										}}>
										{item.tier.type || '-'}
									</Cell>
									<Cell>
										<div className="MembersList__status">
											<div
												onClick={(event) => {
													openMemberHandler(event, item, 'info');
												}}>
												<span>
													{item.subscription.status_label}
												</span>
											</div>
											{userHasPermission(
												user,
												PERMISSIONS.CAN_SHOW_THREE_POINTS,
											) && (
												<CustomOptionsMenu
													id={item.id}
													menuContent={
														<>
															<div
																onClick={() => openEditMemberHanlder(item)}
																className="ThreeDotsOptionsMenu__item-edit">
																<NobleIcon name="edit" />
																Edit Info
															</div>
															{item.active === false && (
																<div
																	onClick={() => {
																		console.log('click renew membership');
																	}}
																	className="ThreeDotsOptionsMenu__item-renew">
																	<NobleIcon name="pause" />
																	Renew Membership
																</div>
															)}
															{item.active === true && (
																<>
																	<div
																		onClick={() => sendReminder(item)}
																		className="ThreeDotsOptionsMenu__item-sent">
																		<NobleIcon name="sent" />
																		Send Payment Reminder
																	</div>
																	{!item?.can_delete && (
																		<div
																			onClick={() => openCancelMembership(item)}
																			className="ThreeDotsOptionsMenu__item-cancel">
																			<NobleIcon name="cancelMembership" />
																			Cancel Membership
																		</div>
																	)}
																	{item?.can_delete && (
																		<button
																			onClick={() =>
																				openDeleteMemberHanlder(item)
																			}
																			className="ThreeDotsOptionsMenu__item-delete">
																			<NobleIcon name="delete" />
																			Delete Member
																		</button>
																	)}
																</>
															)}
														</>
													}
												/>
											)}
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
			<div className="MembersList__container_paginate">
				<div className='pageIndicator'>
					<span className='pageIndicator__item'>{renderCurrentPage}</span>
					<span className='pageIndicator__item'>of</span>
					<span className='pageIndicator__item'>{renderTotalPages}</span>
				</div>
				<div className='pageNumber'>
					{hasNextPage && (
						<>
							<div
								className={`pageNumber__btn_paginate last_btn ${pagination.state.page === 0 ? "disabled" : ""}`}
								onClick={() => {
									if (pagination.state.page === 0) return;
									pagination.fns.onSetPage(0)
								}}
							>
								<LastPageIcon fontSize='medium'/>
							</div>
							<div
								className={`pageNumber__btn_paginate ${pagination.state.page === 0 ? "disabled" : ""}`}
								disabled={pagination.state.page === 0}
								onClick={() => {
									if (pagination.state.page === 0) return;
									pagination.fns.onSetPage(pagination.state.page - 1)
								}}
							>
								<KeyboardArrowLeftIcon fontSize='medium'/>
							</div>
						</>
					)}
					{getPageNumbers().map((pageNumber) => (
						<div
							key={pageNumber}
							className={`pageNumber__item_paginate ${pagination.state.page + 1 === pageNumber ? 'active' : ''}`}
							onClick={() => pagination.fns.onSetPage(pageNumber - 1)}
						>
							<span>{pageNumber}</span>
						</div>
					))}
					{hasNextPage && (
						<>
							<div
								className={`pageNumber__btn_paginate ${pagination.state.page + 1 === totalPages ? "disabled" : ""}`}
								onClick={() => {
									if (pagination.state.page + 1 === totalPages) return;
									pagination.fns.onSetPage(pagination.state.page + 1)
								}}
							>
								<KeyboardArrowRightIcon fontSize='medium'/>
							</div>
							<div
								className={`pageNumber__btn_paginate ${pagination.state.page + 1 === totalPages ? "disabled" : ""}`}
								onClick={() => {
									if (pagination.state.page + 1 === totalPages) return;
									pagination.fns.onSetPage(totalPages - 1)
								}}
							>
								<LastPageIcon fontSize='medium'/>
							</div>
						</>
					)

					}
				</div>
			</div>
			<UserMemberModal
				members={data.nodes}
				action={actionItem}
				setActionItem={setActionItem}
				clickedItemId={clickedItem}
				isOpen={isOpen}
				isModalOpen={isOpenDeleteMemberModal || isOpenCancelMembershipModal}
				onRequestClose={closeHandler}
				setClickedItem={setClickedItem}
			/>
			<DeleteModal
				isDeleteModalOpen={isOpenDeleteMemberModal}
				onCloseHandler={closeHandlerDeleteMemberModal}
				descriptionDelete={deleteMemberMessage}
				deleteHandler={deleteMember}
				clickedItem={clickedItem}
			/>
			<CancelMembershipModal
				isOpen={isOpenCancelMembershipModal}
				onCloseHandler={closeCancelMembershipModal}
				callback={cancelMembership}
				clickedItem={clickedItem}
			/>
		</div>
	);
}

export default MembersList;
