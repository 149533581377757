import React, { useContext, useState } from 'react';
import './memberInfo.scss';
import FormDisplayGroup from '../../../formDisplayGroup';
import Button from '../../../button';
import NobleIcon from '../../../nobleIcon';
import { AppContext, AuthContext } from '../../../../context';
import ThreeDotsOptionsMenu from '../../../threeDotsOptionsMenu';
import DeleteModal from '../../deleteModal';
import { deleteMemberById } from '../../../../services/api/members/deleteMember';
import { toast } from 'react-toastify';
import { formattedPhoneNumber } from '../../../../helpers/formattedPhoneNumber';
import FormDisplayImage from '../../../formDisplayImage';
import { Info } from '../../../icons';
import { deleteMemberMessage } from '../../../../services/constants';
import { sendPaymentReminder } from '../../../../services/api/members/sendReminder';
import CancelMembershipModal from 'components/modals/cancelMembershipModal';
import { cancelMembershipById } from 'services/api/members/cancelMembershipById';
import { PERMISSIONS } from 'helpers/constants';
import { userHasPermission } from 'helpers/hasPermission';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function MemberInfo({ setAction, onRequestClose, ...rest }) {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [isOpenDeleteMemberModal, setIsOpenDeleteMemberModal] = useState(false);
	const [memberState, setMemberState] = useState(undefined);
	const [isOpenCancelMembershipModal, setisOpenCancelMembershipModal] =
		useState(false);

	const { member, clickOutsideMenuRef, setLoading, setRefetchMembers } =
		useContext(AppContext);
	const { user } = useContext(AuthContext);
	const openDeleteMemberHanlder = async (member) => {
		setMemberState(member);
		setIsOpenDeleteMemberModal(true);
	};
	const closeHandlerDeleteMemberModal = () => {
		setIsOpenDeleteMemberModal(false);
	};

	const deleteMember = async (memberId) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_DELETE_MEMBER))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await deleteMemberById(memberId);
		if (!response.error) {
			setRefetchMembers(true);
			setIsOpenDeleteMemberModal(false);
			toast.success(`Member deleted`, toastOptions);
		} else {
			setIsOpenDeleteMemberModal(false);
			toast.error(`Error deleting Member`, toastOptions);
		}
		setLoading(false);
	};

	const sendReminder = async (member) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_SEND_MEMBER_REMINDERS))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await sendPaymentReminder(member?.id, member?.is_spouse);
		if (!response.error) {
			setRefetchMembers(true);
			toast.success(`The reminder was sent`, toastOptions);
		} else {
			toast.error(
				`Error: ${response?.data?.response_description}`,
				toastOptions,
			);
		}
		onRequestClose(false);
		setLoading(false);
	};
	const cancelMembership = async (memberId) => {
		if (!userHasPermission(user, PERMISSIONS.CAN_CANCEL_MEMBER_MEMBERSHIP))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
		setLoading(true);
		const response = await cancelMembershipById(memberId);
		if (response && !response?.error) {
			setRefetchMembers(true);
			toast.error(`Membership canceled`, toastOptions);
		} else {
			toast.error(`Error canceling memberhsip`, toastOptions);
		}
		closeCancelMembershipModal();
		setLoading(false);
	};
	const openCancelMembership = (item) => {
		setMemberState(item);
		setisOpenCancelMembershipModal(true);
	};
	const closeCancelMembershipModal = () => {
		setisOpenCancelMembershipModal(false);
		setMemberState(null);
	};

	const openEditMemberHanlder = () => {
		if (member.is_spouse) setAction('edit-spouse');
		if (!member.is_spouse) setAction('edit-member');
	};

	return (
		<div className="MemberInfo" {...rest}>
			<div className="MemberInfo__form">
				<div className="MemberInfo__form__section">
					<div className="section_dual">
						<div className="MemberInfo__form__section__one__left">
							<FormDisplayGroup
								fieldLabel="Full Name"
								fieldValue={member?.full_name}
							/>
							<FormDisplayGroup
								fieldLabel="Address Line 1"
								fieldValue={member?.address_line1}
							/>
							<FormDisplayGroup
								fieldLabel="Address Line 2"
								fieldValue={member?.address_line2}
							/>
							<FormDisplayGroup fieldLabel="City" fieldValue={member?.city} />
							<FormDisplayGroup
								fieldLabel="State"
								fieldValue={member?.state?.name}
							/>
							<FormDisplayGroup
								fieldLabel="Zip Code"
								fieldValue={member?.zip_code}
							/>
						</div>
						<div className="MemberInfo__form__section__one__right">
							<FormDisplayImage url={member?.profile_picture_url} />
							<div>
								<FormDisplayGroup
									fieldLabel="Date Joined"
									fieldValue={member?.member_since}
								/>
								<FormDisplayGroup
									fieldLabel="ID"
									fieldValue={member?.member_number}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="MemberInfo__form__section two-col">
					<FormDisplayGroup fieldLabel="Email" fieldValue={member?.email} />
					<FormDisplayGroup
						fieldLabel="Phone Number"
						fieldValue={
							member?.phone_number
								? formattedPhoneNumber(member?.phone_number)
								: member?.phone_number
						}
					/>
					<FormDisplayGroup
						fieldLabel="Birthday"
						fieldValue={member?.birth_date}
					/>
					{!member?.is_spouse && (
						<FormDisplayGroup
							fieldLabel="Instagram Handle"
							fieldValue={member?.instagram_handle}
						/>
					)}
					{!member?.is_spouse && (
						<FormDisplayGroup
							fieldLabel="LinkedIn (URL)"
							fieldValue={member?.linkedin_url}
						/>
					)}
					<div className="section_dual">
						<FormDisplayGroup
							fieldLabel="Company"
							fieldValue={member?.company}
						/>
						<FormDisplayGroup
							fieldLabel="Occupation"
							fieldValue={member?.occupation}
						/>
					</div>
				</div>

				{member?.spouse_first_name ? (
					<div className="MemberInfo__form__section two-col">
						<div className="section_dual">
							<FormDisplayGroup
								fieldLabel="Spouse Full Name"
								fieldValue={`${member?.spouse_first_name} ${member?.spouse_last_name}`}
							/>
							<FormDisplayGroup
								fieldLabel="Spouse ID"
								fieldValue={member?.spouse_number}
							/>
						</div>
						<div className="section_dual">
							<FormDisplayGroup
								fieldLabel="Spouse Email"
								fieldValue={member?.spouse_email}
							/>
							<FormDisplayGroup
								fieldLabel="Spouse Phone Number"
								fieldValue={
									member?.spouse_phone_number
										? formattedPhoneNumber(member?.spouse_phone_number)
										: null
								}
							/>
						</div>
					</div>
				) : (
					<></>
				)}

				{!member?.is_spouse && (
					<div className="MemberInfo__form__section three-col">
						<div className="section_dual">
							<FormDisplayGroup
								fieldLabel="Primary Restaurant"
								fieldValue={member?.venue?.name}
							/>
							<FormDisplayGroup
								fieldLabel="Referring Member"
								fieldValue={member?.referred_by}
							/>
						</div>
						<div className="section_dual">
							<FormDisplayGroup
								fieldLabel="Go-To Dinner Beverage"
								fieldValue={member?.go_to_dinner_beverage}
							/>
							<FormDisplayGroup
								fieldLabel="GRM Referral"
								fieldValue={member?.grm?.name ? member?.grm?.name : member?.grm_referral}
							/>
						</div>
						<div className="section_dual">
							<FormDisplayGroup
								fieldLabel="How did you hear about us?*"
								fieldValue={member?.hear_about_us}
							/>
							<FormDisplayGroup
								fieldLabel="Restaurant Sale Was Made"
								fieldValue={member?.venue_sale?.name}
							/>
						</div>
					</div>
				)}
				<div className="MemberInfo__form__section three-col">
					<FormDisplayGroup
						fieldLabel="Tier Type"
						fieldValue={member?.tier.type}
						icon={<Info />}
					/>						
					<div className="section_dual">
						<FormDisplayGroup
							fieldLabel="Subscription Status"
							fieldValue={member?.subscription_status_label}
						/>
						<FormDisplayGroup
							fieldLabel="Cost"
							fieldValue={`${
								member?.subscription_amount
									? `$ ${member?.subscription_amount}`
									: '--'
							}`}
						/>
					</div>
				</div>
				<div className="MemberInfo__form__section two-col frequently">
					<div className="FormDisplayGroup">
						<label className="FormDisplayGroup__label">
							Frequently Visited Locations
						</label>
						<div className="FormDisplayGroup__value FromDisplayGroupFrequently">
							{member?.dinning_history?.length === 0 ? (
								<span>--</span>
							) : (
								member?.dinning_history?.map((values, index) => (
									<div
										className="FromDisplayGroupFrequently__section"
										key={index}>
										<div>
											<label>{values.venue}</label>
										</div>
										<div className="date-time">
											<span>{values.date}</span>
											<span>{values.time}</span>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
				<div className="MemberInfo__form__section three-col">
					<div className="section-deeplink">
						<FormDisplayGroup
							fieldLabel="Deep link URL"
							fieldValue={member?.deep_link}
							toCopy={true}
						/>
					</div>
					{!member?.is_spouse && (
						<div className="section-deeplink">
							<FormDisplayGroup
								fieldLabel="Membership link URL"
								fieldValue={member?.membership_link}
								toCopy={true}
							/>
						</div>
					)}
				</div>
				{userHasPermission(user, PERMISSIONS.CAN_SHOW_MEMBER_OPTIONS) && (
					<div className="MemberInfo__form__section buttons-section">
						<Button
							displayLabel={
								<div>
									<NobleIcon name="sent" />
									<span>Send Payment Reminder</span>
								</div>
							}
							buttonWrapperClass="sent-btn"
							color="black"
							size="full"
							onClick={() => sendReminder(member)}
						/>
						<div className="section-btn-2">
							<Button
								displayLabel={
									<div>
										<NobleIcon name="edit" />
										<span>Edit Info</span>
									</div>
								}
								buttonWrapperClass="edit-btn"
								color="black"
								size="full"
								onClick={() => openEditMemberHanlder()}
							/>
							<div className="options" onClick={setToggleMenu}>
								<>
									{member?.active && (
										<>
											<ThreeDotsOptionsMenu
												toggleMenu={toggleMenu}
												setToggleMenu={setToggleMenu}
												outsideClickRef={clickOutsideMenuRef}
												menuContent={
													<>
														{!member?.can_delete && (
															<button
																onClick={() => openCancelMembership(member)}
																className="options__item-close">
																<NobleIcon name="cancelMembership" />
																Cancel Membership
															</button>
														)}
														{member?.can_delete && (
															<button
																onClick={() => openDeleteMemberHanlder(member)}
																className="options__item-delete">
																<NobleIcon name="delete" />
																Delete Member
															</button>
														)}
													</>
												}
											/>
											{!toggleMenu && <span>More</span>}
										</>
									)}
								</>
							</div>
						</div>
					</div>
				)}				
			</div>
			<DeleteModal
				isDeleteModalOpen={isOpenDeleteMemberModal}
				onCloseHandler={closeHandlerDeleteMemberModal}
				descriptionDelete={deleteMemberMessage}
				deleteHandler={deleteMember}
				clickedItem={memberState}
				deleteItemType="Member"
			/>
			<CancelMembershipModal
				isOpen={isOpenCancelMembershipModal}
				onCloseHandler={closeCancelMembershipModal}
				callback={cancelMembership}
				clickedItem={memberState}
			/>
		</div>
	);
}

export default MemberInfo;
