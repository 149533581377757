import FormDisplayImage from 'components/formDisplayImage';

import './FromDisplayImageInvestor.scss';
import { useState } from 'react';
import { ALLOWED_FILE_SIZE, ALLOWED_FILE_SIZE_MESSAGE, ALLOWED_FILE_TYPES, ALLOWED_FILE_TYPES_MESSAGE } from '../../addInvestors.constant';

const FormDisplayImageInvestor = ({ url, disabled, onChange, isInvestor = false }) => {

   const [error, setError] = useState('');
   const [previewUrl, setPreviewUrl] = useState('');

   const validateFile = (file) => {
      if (!file) return 'Please select a file';
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
         return ALLOWED_FILE_TYPES_MESSAGE
      }
      if (file.size > ALLOWED_FILE_SIZE) {
         return ALLOWED_FILE_SIZE_MESSAGE
      }
      return '';
   };

   const formatBytes = (bytes) => (bytes / 1024 ** 2).toFixed(2);

   function readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
      })
    };

	const handleFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			onChange(e);
			const imgFile = e.target.files[0];
         const imgMBSize = formatBytes(imgFile.size);
			const validationError = validateFile(imgFile);
			const imgType = imgFile.type;

			if (imgMBSize > 10) return setError('File needs to be less than 10mb');				
			if (imgType !== 'image/png' && imgType !== 'image/jpeg') return setError('File needs to be jpg or png');
			if (validationError.length > 0) return setError(validationError);			

         setError('');

         let imageDataUrl = await readFile(imgFile)
         setPreviewUrl(imageDataUrl);
		}		
	};

   const handleBrowseClick = () => {
      document.getElementById('currentHeadshot').click();
   }

   return (
      <div className="FormDisplayImageInvestor">
         <div className="FormDisplayImageInvestor__title">
            <span>Profile Photo</span>
         </div>
         <FormDisplayImage
            url={url}
            previewUrl={previewUrl}
            disabled={disabled || false}
            isInvestor={isInvestor}
         />
         <input
            id="currentHeadshot"
            type="file"
            accept={ALLOWED_FILE_TYPES.join(', ')}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            name="currentHeadshot"
         />
         <div className="FormDisplayImageInvestor__browse_photos">
            <span onClick={handleBrowseClick}>Browse Photos</span>
         </div>
         <div className="FormDisplayImageInvestor__legend">
            <legend>
               <span>Photos are required for members to access their perks.</span>
            </legend>
         </div>
      </div>
   )
}

export default FormDisplayImageInvestor;
