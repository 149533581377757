import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber, validatePhoneNumberUSA } from '../../../helpers/formattedPhoneNumber';

const adaptarAddInvestor = (data) => {
   const keysInvestor = Object.keys(data);

   let dataPost = {};

   for (let i = 0; i < keysInvestor.length; i++) {
      if (
         (data[keysInvestor[i]].value.length > 0 || data[keysInvestor[i]].value !== 0) &&
         data[keysInvestor[i]].valid !== undefined
      ) {
         if (keysInvestor[i] === 'phoneNumber') {
            const phoneValue = data[keysInvestor[i]].value;

            if (!validatePhoneNumberUSA(phoneValue)) {
               return {
                  error: true,
                  data: 'Invalid phone number',
               }
            }
            dataPost[keysInvestor[i]] = deformatPhoneNumber(data[keysInvestor[i]].value);
         } else {
            dataPost[keysInvestor[i]] = data[keysInvestor[i]].value;
         }
      }
   }
   
	const formData = new FormData();
	for (const key in dataPost) {
      if (key === 'isInvestor') {
         formData.append(key, dataPost[key]);
      } else {
         if (dataPost[key]) formData.append(key, dataPost[key]);
      }
	}

   return formData;
}

export const addInvestor = async (addInvestorForm) => {
   const dataAddInvestor = adaptarAddInvestor(addInvestorForm);

   const options = {
      method: 'post',
      url: '/dashboard/investor',
      information: dataAddInvestor,
   };

   let data = null;

   if (dataAddInvestor.error === true) {
      return dataAddInvestor;
   } else {
      const response = await axiosRequest(options);
      const addInvestorResponse = response.data;

      data = adapterResponse(addInvestorResponse);

      return data;
   }
}


const adapterResponse = (data) => {
   if (data.response_code === 0) {
      return data;
   } else {
      return {
         error: true,
         data: data.response_description,
      }
   }
}
